<template>
    <v-stepper-content step="3" class="py-0 px-2">
        <p class="header-title">Adresgegevens</p>

        <v-form :ref="'stepForm'" lazy-validation :disabled="isSubmitted">
            <AddressBase :address="address" />
            <TermsAndConditions :data="data" />
        </v-form>

        <v-btn
            color="primary" block class="mt-4"
            @click.stop="validate" :disabled="isSubmitted">
            Registreer
        </v-btn>

        <v-row class="justify-center text-center" no-gutters>
            <v-card-actions>
                <v-container >
                    <v-row dense>
                            <v-col cols="12">
                            Foutje gemaakt?
                            <span class="link" @click="previous">Ga terug.</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-row>
    </v-stepper-content>
</template>

<script>
module.exports = {
    props: {
        previous: Function,
        register: Function,
        rules: Object,
        data: Object,
        address: Object
    },
    beforeCreate: function () {
        this.$options.components.AddressBase = require('@/components/common/AddressBase').default,
        this.$options.components.TermsAndConditions = require('@/components/common/TermsAndConditions').default
    },
    data: () => ({
        isSubmitted: false,
    }),
    methods:
    {
        validate()
        {
            let v = this.$refs.stepForm.validate()
            if (v) {
                // continue to next
                this.isSubmitted = true;
                this.register();
            }
        }
    }
}
</script>
