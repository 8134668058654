export default {
    data: function() {
        return {
            decimalSeparator: ",",
            numbers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        }
    },
    methods: 
    {
        array_move(arr, old_index, new_index)
        {
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        },
        isNumber(evt)
        {
            if(evt == undefined) return;

            const keyPressed = evt.key;
            if (!this.numbers.includes(keyPressed)) {
                evt.preventDefault();
            }
        },
        isNonNumeric(evt)
        {
            if(evt == undefined) return;

            const keyPressed = evt.key;
            if(this.numbers.includes(keyPressed))
            {
                evt.preventDefault();
            }
        },
        isPhoneNumberKey(evt)
        {
            if(evt == undefined) return;

            const allowedChars = ["+", " "];
            const keyPressed = evt.key;
            if(!this.numbers.includes(keyPressed) && !allowedChars.includes(keyPressed))
            {
                evt.preventDefault();
            }
        },
        isCurrency(currentText, evt)
        {
            let numbers = [...this.numbers];
            const separator = [this.decimalSeparator, '.'];

            let keysAllowed = numbers.concat(separator);
            const keyPressed = evt.key;
            
            // separator should only occur once
            const replacedKey = evt.key.replace('.', this.decimalSeparator);
            if (separator.includes(replacedKey) && currentText.includes(replacedKey))
            {
                evt.preventDefault();
                return;
            }

            if(!keysAllowed.includes(keyPressed))
            {
                evt.preventDefault();
                return;
            }
        },

        // text fields
        getNextElement(event, formName, nextElement)
        {
            const item = event.currentTarget.closest(`.${formName}`);
            if (item)
            {
                const next = item.querySelector(`.${nextElement} input`)

                if (next)
                {
                    next.focus();
                }
                
            }
        },
    }
}