<template>
    <v-stepper-content step="1" class="py-0 px-2">

        <p class="header-title">Logingegevens</p>

        <v-form :ref="'stepForm'" lazy-validation>
            <v-text-field v-model="data.email"
            :rules="[rules.required, rules.email]"
            label="E-mailadres*"
            required />

            <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.password]"
                color="primary"
                :type="showPassword ? 'text' : 'password'"
                label="Wachtwoord*"
                v-model="data.password"
                @click:append="showPassword = !showPassword"
                @keydown.native.enter="validate"
                required>
            </v-text-field>
        </v-form>

        <v-btn color="primary" block class="my-4"
               @click.stop="validate" :disabled="isVerifying">
            Volgende
        </v-btn>
    </v-stepper-content>
</template>

<script>
module.exports = {
    props: {
        checkEmail: Function,
        rules: Object,
        data: Object,
        isVerifying: Boolean
    },
    data: () => ({
        showPassword: false,
    }),
    methods:
    {
        validate()
        {
            let v = this.$refs.stepForm.validate()
            if (v) {
                this.checkEmail();
            }
        }
    }
}
</script>
