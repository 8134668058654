import { Address } from './Address';
import { Driver } from './Driver';

export class NewAccount {
    acceptTerms = false;

    address = new Address();
    driver = new Driver();

    mapToRegistrationRequest() {
        let accountRegistrationRequest = 
        {
            "loginInformation": {
                "emailAddress": this.driver.email,
                "password": this.driver.password,
            },
            "basicPersonInformation": {
                "firstname": this.driver.firstname,
                "lastName": this.driver.lastname,
                "dateOfBirth": this.driver.birthdate,
                "phoneNumber": this.driver.phone,
            },
            "addressInformation": {
                "street": this.address.street,
                "houseNumber": this.address.housenr,
                "zipCode": this.address.postcode,
                "city": this.address.city,
                "country": this.address.country,
                "company": this.address.company
            }
        }
        
        return accountRegistrationRequest;
    }
}