<template>
    <v-stepper-content step="2" class="py-0 px-2">
        <p class="header-title">Persoonsgegevens</p>

        <v-form :ref="'stepForm'" lazy-validation>
             <v-text-field v-model="data.firstname"
                @keypress="isNonNumeric($event)"
                :rules="[rules.required, rules.nameLength, rules.validName]"
                label="Voornaam*"
                required />

            <v-text-field v-model="data.lastname"
                @keypress="isNonNumeric($event)"
                :rules="[rules.required, rules.nameLength, rules.validName]"
                label="Achternaam*"
                required />

            <DatepickerFormatted 
                :date="data.birthdate" 
                :isRequired="true" @changed="data.birthdate = $event" 
                :showIcon="false" 
                :label="`Geboortedatum*`" 
                :isBirthdate="true" 
                :isAccountHolder="true" />

            <v-text-field v-model="data.phone"
                label="Telefoonnummer" 
                @keypress="isPhoneNumberKey($event)"
                :rules="[rules.phoneNumberLength]"
                @keydown.native.enter="validate" />

            <v-btn color="primary" block class="mt-4"
                @click.stop="validate">
                    Volgende
            </v-btn>
        </v-form>
       
        <v-row class="justify-center text-center" no-gutters>
                <v-card-actions>
                    <v-container >
                        <v-row dense>
                             <v-col cols="12">
                                Foutje gemaakt?
                                <span class="link" @click="previous">Ga terug.</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
        </v-row>
    </v-stepper-content>
</template>

<script>
module.exports = {
    props: {
        next: Function,
        previous: Function,
        isPhoneNumberKey: Function,
        isNonNumeric: Function,
        rules: Object,
        data: Object
    },
    beforeCreate: function () {
        this.$options.components.DatepickerFormatted = require('@/components/common/DatepickerFormatted').default
    },
    data: () => ({
        activePicker: null,
        menu: false,
    }),
    watch: {
        menu (val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    methods:
    {
        save (date) {
            this.$refs.menu.save(date)
        },
        validate()
        {
            let v = this.$refs.stepForm.validate()
            if (v) {
                // continue to next
                this.next();
            }
        }
    }
}
</script>
