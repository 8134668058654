<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Registratie Mijn Roadpursuit`" :intro="``">
        <LoadingIcon :isLoading="isLoading" />
        <template v-if="!submitted">
            <v-container>
                <v-row no-gutters>
                    <v-col sm="6" offset-sm="3">
                        <v-stepper v-model="step">
                            <v-stepper-header>
                                <v-stepper-step step="1" :complete="step > 1" />
                                <v-divider />
                                <v-stepper-step step="2" :complete="step > 2" />
                                <v-divider />
                                <v-stepper-step step="3" />
                            </v-stepper-header>

                            <v-stepper-items>
                                <LoginDetails :data="newAccount.driver"
                                    :rules="rules"
                                    :isVerifying="isVerifying"
                                    :checkEmail="checkEmail" />

                                <PersonalDetails :data="newAccount.driver"
                                    :isPhoneNumberKey="isPhoneNumberKey"
                                    :isNonNumeric="isNonNumeric"
                                    :rules="rules"
                                    :next="next"
                                    :previous="previous" />

                                <AddressDetails :data="newAccount"
                                    :address="newAccount.address"
                                    :rules="rules"
                                    :register="register"
                                    :previous="previous" />
                            </v-stepper-items>
                        </v-stepper>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <v-container v-if="submitted" class="pt-0">
            <v-row v-if="!isLoading">
                <v-col class="mx-2 my-0" v-if="isSuccessful">
                        Uw registratie is ontvangen. U hoeft uw account nu alleen nog maar te activeren. Check uw inbox.
                </v-col>
                <v-col class="mx-2 my-0" v-if="!isSuccessful">
                    <div>
                        Er ging is mis bij het registreren van uw account. Probeer het nog eens. Blijft het probleem zich voordoen, neem dan contact op met
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a
                                style="color:#76baff"
                                target="_blank"
                                href="mailto:service@roadpursuit.com"
                                @click.stop
                                v-on="on">
                                service@roadpursuit.com
                            </a>
                            </template>
                            Opent uw mail app
                        </v-tooltip>
                        .
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate'
import LoginDetails from '@/components/registration/LoginDetails'
import PersonalDetails from '@/components/registration/PersonalDetails'
import AddressDetails from '@/components/registration/AddressDetails'
import GlobalMixin from "@/mixins/global.mixin";
import DataMixin from "@/mixins/data.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";
import LoadingIcon from '@/components/common/LoadingIcon';
import { NewAccount } from '@/models/NewAccount';

export default {
    name: 'Registratie',
    components: 
    {
        BannerTemplate,
        LoginDetails,
        PersonalDetails,
        AddressDetails,
        LoadingIcon
    },
    mixins: [GlobalMixin, DataMixin, MetaTagsMixin],
    data: () => ({
        bannerSource: require('@/assets/album/Login/login-01.jpg'),
        step: 1,
        submitted: false,
        isSuccessful: false,
        valid: false,
        isVerifying: false,
        isLoading: false,
        newAccount: new NewAccount(),
    }),
    metaInfo() {
        return {
        title: '| Registratie',
        meta: [
            this.getKeywords(`Registratie, Mijn Roadpursuit`),
            this.getMetaDescription(`Roadpursuit registratie`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Roadpursuit registratie`, `Roadpursuit registratie`, `${window.location.origin}${this.bannerSource}`, 'Registratie'),
        ]
        };
    },
    beforeCreate(){
        this.$store.dispatch('menuHelper/getCountries');
    },
    methods: {
        next() {
            if(this.isVerifying) return;
            this.step += 1
        },
        previous() {
            this.step -= 1
        },
        checkEmail()
        {
            this.isVerifying = true;
            // call api to check if email has already been used.
            this.$store.dispatch('accountModule/isEmailAvailable', this.newAccount.driver.email)
            .then(isAvailable => {
                this.isVerifying = false;
                if (isAvailable)
                {
                    this.next();
                }
                else
                {
                    this.$store.commit('dialog/showDialog', 'Op het door u ingevulde e-mailadres is al een account geregistreerd. Bent u misschien uw wachtwoord vergeten?');
                }
                
            })
        },
        register() {
            this.submitted = true;
            this.isLoading = true;
            this.$store.dispatch(`accountModule/registerAccount`, this.newAccount.mapToRegistrationRequest())
            .then(() => {
                this.isSuccessful = true;
                this.isLoading = false;
            })
            .catch((error) => {
                this.isSuccessful = false;
                this.isLoading = false;
                this.$store.commit('dialog/showDialog', error);
            })
        }
    },
    mounted()
    {
        this.$store.commit('setFooterVisibility', true);
    },
}
</script>
